<template>
  <div>
    <b-modal
      id="modal-approve-adjustment"
      cancel-variant="outline-secondary"
      :ok-title="$t('Submit')"
      :cancel-title="$t('Close')"
      :ok-variant="okVariant"
      :modal-class="modalClass"
      centered
      :title="title"
      scrollable
      @ok="confirmApprove"
      @hidden="resetForm"
    >
      <!-- form -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          @submit.prevent="handleSubmit(validationFormApprove)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- Transaction Id -->
            <b-col cols="12">
              <b-form-group
                :label="$t('Transaction id')"
                label-for="transactionId"
              >
                <b-form-input
                  id="name"
                  v-model="adjustment.reference"
                  trim
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- Username -->
            <b-col cols="12">
              <b-form-group :label="$t('Username')" label-for="transactionId">
                <b-form-input
                  id="name"
                  v-model="adjustment.username"
                  trim
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- Currency -->
            <!-- <b-col cols="12">
              <b-form-group :label="$t('Currency')" label-for="currency">
                <b-form-input
                  id="currency"
                  v-model="adjustment.currency"
                  disabled
                />
              </b-form-group>
            </b-col> -->

            <!-- Amount -->
            <b-col cols="12">
              <b-form-group :label="$t('Amount')" label-for="amount">
                <b-form-input
                  id="amount"
                  v-model="adjustment.amount"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- Note -->
            <b-col cols="12">
              <b-form-group label-for="note">
                <label for="remark">{{ $t("Note") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="note"
                >
                  <b-form-textarea
                    id="note"
                    v-model="adjustment.reason"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BFormInvalidFeedback,
} from "bootstrap-vue";
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from "@validations";
import { numberFormat, resolveCurrency } from "@core/utils/filter";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import tagStoreModule from "@/views/apps/user/tag/tagStoreModule";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BFormFile,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    adjustmentData: {
      type: [Object, null],
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      required,
      title: "",
      okVariant: "primary",
      modalClass: "modal-primary",
      adjustment: {
        id: null,
        reference: null,
        username: null,
        currency: null,
        amount: null,
        status: this.type,
        reason: null,
      },
    };
  },
  watch: {
    adjustmentData: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.adjustment = {
            id: newVal.id,
            reference: newVal.ref,
            username: newVal.user ? newVal.user.username : "n/a",
            currency: newVal.user.currency,
            amount: newVal.amount / 1000,
            reviewAmount: numberFormat(newVal.amount / 1000),
            status: 2,
            reason: "",
          };
        }
      },
    },
    type: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.title =
            newVal == 4
              ? this.$t("Approve adjustment transaction")
              : this.$t("Reject adjustment transaction");
          this.okVariant = newVal == 4 ? "success" : "danger";
          this.modalClass = newVal == 4 ? "modal-success" : "modal-danger";
          this.adjustment.status = newVal;
        }
      },
    },
  },
  
  methods: {
    validationFormApprove() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          store
            .dispatch(
              "payment-adjustment/updateAdjustmentStatus",
              this.adjustment
            )
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code == "00") {
                  this.$emit("refetch-data");
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || "success",
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                  this.$nextTick(() => {
                    setTimeout(() => {
                      this.$bvModal.hide("modal-approve-adjustment");
                      this.resetForm();
                    }, "500");
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              }
            })
            .catch((error) => {
              const { response } = error;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "Error Proccess",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    confirmApprove(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormApprove();
    },
    resetForm() {
      this.$emit("update:adjustmentData", null);
      this.$emit("update:type", null);
    },
	
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: 100% !important;
}
</style>
