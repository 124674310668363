import TagService from '@/libs/tag.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchTagTypes(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				TagService.getTagTypeLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchTagTypesFilter(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				TagService.getTagTypeListsFilter(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchTagTypeDetail(ctx, tagTypeId) {
			return new Promise((resolve, reject) => {
				TagService.getTagTypeDetail(tagTypeId)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		editTagType(ctx, tagTypeData) {
			return new Promise((resolve, reject) => {
				TagService.updateTagType(tagTypeData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addTag(ctx, tagData) {
			return new Promise((resolve, reject) => {
				TagService.addTag(tagData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		editTag(ctx, tagTypeData) {
			return new Promise((resolve, reject) => {
				TagService.editTag(tagTypeData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	}
}
