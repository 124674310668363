/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class TagService {

	async getTagTypeLists(queryParams) {
		const pars = {
			pageNumber: queryParams.page
		}
		const response = await instance.get('/api/tag-type/index', { headers: authHeader(), params: pars })
		const datas = {
			tagTypes: response.data.data,
			total: response.data.count,
			pageSize: queryParams.page === 1 ? response.data.data.length : 25
		}
		return datas
	}
	async getTagTypeListsFilter(queryParams) {
		const pars = {
			pageNumber: queryParams.page
		}
		const response = await instance.get('/api/tag/index', { headers: authHeader(), params: pars })
		const datas = {
			tagTypes: response.data.data,
			total: response.data.count,
			pageSize: queryParams.page === 1 ? response.data.data.length : 25
		}
		return datas
	}

	async getTagTypeDetail(tagTypeId) {
		const response = await instance.get('/api/tag-type/detail', { headers: authHeader(), params: { tagTypeId } })
		return response.data
	}

	updateTagType(tagTypeData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('tagTypeId', tagTypeData.tagTypeId)
		data.append('name', tagTypeData.name)
		data.append('description', tagTypeData.description)
		// data.append('tagIds', tagTypeData.tagIds)
		tagTypeData.tagIds.map((item, index) => {
			data.append(`tagIds[${index}]`, item)
		})

		return instance.post('/api/tag-type/edit', data, { headers: authHeader() })
	}

	addTag(tagData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', tagData.name)
		data.append('description', tagData.description)
		data.append('color', tagData.color)

		return instance.post('/api/tag/create', data, { headers: authHeader() })
	}

	editTag(tagData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', tagData.name)
		data.append('description', tagData.description)
		data.append('color', tagData.color)
		data.append('tagId', tagData.tagId)
		return instance.post('/api/tag/edit', data, { headers: authHeader() })
	}
}

export default new TagService()
